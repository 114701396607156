import * as React from "react"
import PropTypes from "prop-types"
import { withTrans } from "../i18n/withTrans"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"


const Layout = ({ children,t }) => {
  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          padding: `0 `,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            padding: "10px",
            zIndex:"3",
            background: "#b2b2b2",
          }}
        >
            <StaticImage
                src="../images/logo_alt.png"
                style={{width:"50px", margin: "0 auto", marginBottom: "5px", marginTop: "20px", marginLeft:"calc(50vw - 40px)"}}
                placeholder="tracedSVG"
                alt="logo"/>
            <p className="p11G" style={{textAlign: "center", marginBottom: "50px"}}>
            <Link to="/impressum" style={{textDecoration:"none", color:"black"}}>
                {t("impressum")}
            </Link>
            <span> | </span>
            <Link to="/datenschutz" style={{textDecoration:"none", color:"black"}}>
                {t("datenschutz")}
            </Link>
            </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
