import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import Seo from "../seo"

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

import { withTrans } from "../../i18n/withTrans"
import SearchBar from "../searchbar"
import Text from "../text"
import i18next from "../../i18n/config"

const Ausstellung = ({t, ...props}) => {
    const {
        pageContext: {
            node,
            next,
            previous,
            objekte,
            images,
        },
    } = props;

    const [state, setState] = useState({
        query: "",
    })

    const handleInputChange = (change) => {setState({query: change.target.value});}
    const isUnfiltered = (obj) => {return !state.query
        || (obj.inventar && obj.inventar.toLowerCase().includes(state.query.toLowerCase()))
        || (obj.urheber && obj.urheber.toLowerCase().includes(state.query.toLowerCase()))
        || (obj.urheberEn && obj.urheberEn.toLowerCase().includes(state.query.toLowerCase()))
        || (obj.quelltitel && obj.quelltitel.toLowerCase().includes(state.query.toLowerCase()))
        || (obj.quelltitelEn && obj.quelltitelEn.toLowerCase().includes(state.query.toLowerCase()))
        // || (obj.ausstellung && obj.ausstellung.toLowerCase().includes(state.query.toLowerCase()))
        // || (obj.ausstellungEn && obj.ausstellungEn.toLowerCase().includes(state.query.toLowerCase()))
    }


    const getImageObject = (url) => images.filter(img => img.node.name.split(".pdf")[0] == (url) )[0]
    const tr = (de,en)=>(i18next.language==="de"?de:en)

    return (
        <Layout>
            <Seo title={node.ausstellung}/>
            <main id="maincontent">
            <div style={{marginLeft: "15vw", marginRight:"15vw",marginTop:"20px"}}>
                <SearchBar handler={handleInputChange}/>

                    <div style={{maxWidth:"500px"}} className="clearfix">
                        <p className="p11G" style={{marginTop:"30vh",}}>{tr(node.ausstellung, node.ausstellungEn)}</p>
                        <p className="p11B">{tr(node.jahr, node.jahrEn)}, {tr(node.ort, node.ortEn)}</p>
                        <p className="p11B">{tr(node.creditsDe, node.creditsEn)}</p>
                        <p className="p11GM"><br/>
                          <Text text={tr(node.textDe, node.textEn)}/>
                        </p>
                        <p className="p11B" style={{marginTop:"50px"}}>
                        { previous &&
                          <Link to={"/ausstellungen/"+previous.ausstellung.toLowerCase().replace(/\W/g, '')}
                            style={{textDecoration: "underline", color:"black", float:"left"}}>
                              {t("ausstellung.prev")}
                          </Link>
                        }
                        { next &&
                          <Link to={"/ausstellungen/"+next.ausstellung.toLowerCase().replace(/\W/g, '')}
                            style={{textDecoration: "underline", color:"black", float:"right"}}>
                              {t("ausstellung.next")}
                          </Link>
                        }
                        </p>

                    </div>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                        marginBottom:"30vh", marginTop:"50px",
                    }}>

                    {objekte.length > 0 && objekte.map(obj => (
                        <Link to={`${obj.id.toLowerCase().replace(/\W/g, '')}`}
                            style={{textDecoration: "none", display: isUnfiltered(obj)?"block":"none"  }}
                            key={obj.id}>
                            <div style={{margin: "auto", padding:"0.5em"}}>
                                <div style={{
                                    height: "0", paddingTop:"100%", //hack to force 1:1 aspect ratio
                                }}>
                                  { !obj.datatype &&
                                    <GatsbyImage image={getImage( getImageObject(obj.objekturl.split("\n")[0]) ?
                                          getImageObject(obj.objekturl.split("\n")[0]).node :{})}
                                      style={{
                                        width:"100%", transform:"translateY(-100%)",
                                        border:"#696969", borderWidth:"2px", borderStyle:"solid",
                                    }}/>
                                  }
                                  { obj.datatype === "audio" &&
                                    <StaticImage
                                      src="../../images/audio_track.png"
                                      style={{width:"100%",  height:"0", paddingTop:"100%", transform:"translateY(-100%)",border:"#696969", borderWidth:"2px", borderStyle:"solid",}}
                                      alt={obj.quelltitel} placeholder="tracedSVG" aspectRatio={1}/>
                                  }
                                </div>
                                <p style={{fontFamily:"Gotham", fontSize:"11pt", color: "black", textDecoration:"none", lineHeight: "14pt", marginTop:"10px"}}>
                                      {tr(obj.quelltitel, obj.quelltitelEn)} <br/>
                                    <span style={{fontFamily:"Gotham-Medium"}}>
                                      {obj.inventar} <br/>
                                      {tr(obj.urheber, obj.urheberEn)}
                                    </span>
                                </p>
                            </div>
                        </Link>
                    ))}


                    <div style={{marginBottom: "30vh"}}/>

                </div>
            </div>
            </main>
        </Layout>
    )
}

export default withTrans(Ausstellung)
