import React from "react"

const Text = (props) => {
  const arr = props.text.split('\n');
  return arr.map((line, index) => {
    if (line.match(/~~.*~~/)) {
      return <>{line.split(/~~/).map((frag, i) => i%2===1?
        <span style={props.tildeStyle}>{frag}</span> :
        <>{frag}</>
      )}</>
    }
    // dont break line on last \n
    if (index === arr.length-1) {
      return (<>{line}</>)
    }
    // break lines on \n
    return (<>{line}<br/></>)
  });
}


export default Text
