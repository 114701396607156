import React, { useState, useRef, useEffect } from "react"

import { withTrans } from "../i18n/withTrans"
import { StaticImage } from "gatsby-plugin-image"

const Searchbar = ({ t, handler, clearer, initVal }) => {
    const [state, setState] = useState({
        query: "",
        searchActive : false,
    })
    const inputField = useRef();

    useEffect(()=>{
      // console.log(initVal)
    })

    return(
      <div className={"searchSwitch"+(state.searchActive? " active":"")}>
          <input
              ref={inputField}
              type="text"
              aria-label={t("suche")}
              placeholder={t("filter")}
              onChange={handler}
          />
          <StaticImage
              src="../images/search.png"
              alt="search"
              placeholder="none"
              loading="eager"
              onClick={(e)=>{
                  setState({searchActive: !state.searchActive});
                  if(!state.searchActive){
                    e.target.previousSibling.focus()
                  }
                  else{
                    try {clearer(); inputField.current.value="";} catch (e) {}
                    e.target.previousSibling.blur();
                  }
              }}
              />
      </div>
    )
}


export default withTrans(Searchbar)
